import { MODULE_CONFIG } from "../../config/setup"

const states = {
  bsvconfig_details: {
    bsv_data : [],
    bsv_env : "dev",
    bsv_loading: false,
    bsv_load_error: false,
    bsv_current_page: 1,
    bsv_page_size: MODULE_CONFIG.SETTINGS.BSVCONFIG_PER_PAGE_DATA,
    bsv_total: 0,
    bsv_search_key: "",
  },
}
export default states
