export const trainYourBot = "/admin/v1/botBuilder/trainApp"
export const publishYourBot = "/admin/v1/botBuilder/publishApp"
export const getPreemptiveData = "/admin/v1/botBuilder/preemptiveData"
export const voteChatlog = "/admin/v1/botBuilder/voteChatlog"
export const translateMessage = "/admin/v1/translateAndSave"
export const whatsAppFileUpload = "/admin/v1/whatsappInputFileUpload"
export const getChatlogFilters = "/admin/v1/chatLogs/filters"
export const updateUserBlockStatus = "/admin/v1/chatLogs/blockStatus"
export const updateCustomerProfileFormList = "/admin/v1/userProfileWidgets"
export const updateTagsInConversations = "/admin/v1/chatLogs/updateTags"
export const allowedMessageVariations =
  "/admin/v2/botBuilder/getBotMessageVariationList"
export const mediaUpload = "/admin/v2/botBuilder/mediaUpload"
export const mediaDelete = "/admin/v1/bot-builder/delete_media"
export const downloadChatlogs = "/admin/v2/downloadChatlog"
export const bulkFileUpload = "/admin/v1/createBulkActivityOnDB"
export const maintainanceAlert = "/scheduleMaintainance"
export const getCallLog = "/admin/v1/chatLogs/getCallLog"
export const chatAnalyticsBySessionId = "/admin/v1/chatLogs/chatAnalyticsBySessionId"