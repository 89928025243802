import { UPDATE_BSVCONFIG_STATE, UPDATE_BSVCONFIG_DATA } from "./actiontypes"
import states from "./states"

const bsvconfig_details = (state = states.bsvconfig_details, action) => {
  switch (action.type) {
    case UPDATE_BSVCONFIG_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case UPDATE_BSVCONFIG_DATA: {
      const { key, value } = action.payload
      const index = state.bsv_data.findIndex(item => item.key === key)

      if (index === -1) return state

      return {
        ...state,
        bsv_data: [
          ...state.bsv_data.slice(0, index),
          { ...state.bsv_data[index], value },
          ...state.bsv_data.slice(index + 1),
        ],
      }
    }

    default:
      return state
  }
}

export default bsvconfig_details
