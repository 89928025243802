import { CURRENCY } from "../constants/common"
import { MESSAGE_TYPES, PROFILE_ITEM_TYPE } from "../constants/chats"
import { MODULE_CONFIG } from "../config/setup"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"

dayjs.extend(utc)
dayjs.extend(timezone)

const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)

export const log = (...arg) => {
  if (process.env.NODE_ENV === "development") console.log(...arg)
}

export const uniqueId = prefix => {
  let time = new Date().getTime()
  return prefix
    ? `${prefix}-${time}${s4()}${s4()}${s4()}`
    : `${time}${s4()}${s4()}${s4()}`
}

export const getUrlParams = () => {
  let params = {}
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    params[key] = value
  })
  return params
}

export const fileToBase64 = file => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const checkPassword = password => {
  const pattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
  return pattern.test(password)
}

export const isEmptyObject = obj => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false
  }
  return true
}

export const differenceBetweenArrayOfObject = (arr1, arr2, key) => {
  if (key) return arr1.filter(o1 => !arr2.some(o2 => o1[key] === o2[key]))
  return arr1
}

export const findLastIndex = (array, predicate) => {
  let l = array.length
  while (l--) {
    if (predicate(array[l], l, array)) return l
  }
  return -1
}

export const debounced = (func, delay) => {
  let timerId
  return (...args) => {
    if (timerId) clearTimeout(timerId)
    timerId = setTimeout(() => {
      func(...args)
      timerId = null
    }, delay)
  }
}

export const throttled = (func, delay) => {
  let timerId
  return (...args) => {
    if (!timerId) {
      func(...args)
      timerId = setTimeout(() => {
        timerId = null
      }, delay)
    }
  }
}

export const filterOption = (input, option) => {
  if (option?.children)
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

export const filterArrayBySearchText = (text, array, key1, key2 = "") => {
  return array.filter(item => {
    if (key1 && key2) {
      return (
        item[key1] &&
        item[key2] &&
        item[key1] !== undefined &&
        item[key2] !== undefined &&
        item[key1]
          .toString()
          .toLowerCase()
          .trim()
          .concat(item[key2].toString().toLowerCase().trim())
          .indexOf(text.toLowerCase().replaceAll(" ", "")) !== -1
      )
    }
    if (key1)
      return (
        item[key1] &&
        item[key1] !== undefined &&
        item[key1].toString().toLowerCase().indexOf(text.toLowerCase()) !== -1
      )
    return (
      item &&
      item !== undefined &&
      item.toLowerCase().indexOf(text.toLowerCase()) !== -1
    )
  })
}

export const createStringFromTemplate = (template, variables) => {
  const regexp = /{([^{]+)}/g
  return template.replace(regexp, (_unused, key) => {
    return variables[key] === null ? "" : variables[key]
  })
}

export const removeStringLastCharacter = (str, endChar) => {
  if (str && str.length > 0 && str.charAt(str.length - 1) === endChar)
    str = str.substring(0, str.length - 1)
  return str
}

export const moveCaretAtEnd = e => {
  let temp_value = e.target.value
  e.target.value = ""
  e.target.value = temp_value
}

export const reloadPage = () => {
  if (window.location) window.location.reload()
}

export const openUrlInNewTab = (url, target = "_blank") => {
  if (url && url.trim().length > 0) {
    let win = window.open(url, target)
    win.focus()
  }
}

export const formatPrice = (price, currency) => {
  switch (currency) {
    case CURRENCY.RUPEES:
      return Intl.NumberFormat("en-In", {
        style: "currency",
        currency: "INR",
      }).format(price)
    case CURRENCY.DOLLAR:
      return Intl.NumberFormat("en-In", {
        style: "currency",
        currency: "USD",
      }).format(price)
    default:
      return price
  }
}

export const getProcessedSelectedFilters = selected_filters => {
  let filters = {}
  if (selected_filters) {
    const selected_filter_keys = Object.keys(selected_filters)
    if (selected_filter_keys.length > 0) {
      selected_filter_keys.forEach(key => {
        if (selected_filters[key].length > 0) {
          selected_filters[key].forEach(filter_item => {
            if (filters[key] === undefined || filters[key] === null) {
              filters[key] = []
            }
            if (filter_item?.inputKey) {
              filters[key] = {
                ...filters[key],
                [filter_item?.inputKey]: filter_item.value,
              }
            } else filters[key] = [...filters[key], filter_item.value]
          })
        }
      })
    }
  }
  return filters
}

//TODO: replace isImageExist with checkIsImageExist everywhere and test this.
export const isImageExist = url => {
  if (url) {
    const img = new Image()
    img.src = url
    if (!img.complete || img.height === 0) {
      return false
    }
    return true
  }
  return false
}

export const checkIsImageExist = (url, callback) => {
  const img = new Image()
  img.src = url

  if (img.complete) {
    callback(true)
  } else {
    img.onload = () => {
      callback(true)
    }
    img.onerror = () => {
      callback(false)
    }
  }
}

export const uniqBy = (arr, predicate) => {
  if (!Array.isArray(arr)) return []
  const cb = typeof predicate === "function" ? predicate : o => o[predicate]
  const pickedObjects = arr
    .filter(item => item)
    .reduce((map, item) => {
      const key = cb(item)
      if (!key) return map
      return map.has(key) ? map : map.set(key, item)
    }, new Map())
    .values()
  return [...pickedObjects]
}

export const histogramLabelFormatter = val => {
  if (
    val.length > MODULE_CONFIG.GLOBAL.HISTOGRAM_X_AXIS_CHARACTER_SPLIT_COUNT
  ) {
    if (
      val.indexOf(" ", 10) <
      MODULE_CONFIG.GLOBAL.HISTOGRAM_X_AXIS_CHARACTER_SPLIT_COUNT
    )
      return `${val.substring(0, val.indexOf(" ", 10))}\n${val.substring(
        val.indexOf(" ", 10),
        val.length
      )}`
    else if (
      val.indexOf(" ", 10) >
      MODULE_CONFIG.GLOBAL.HISTOGRAM_X_AXIS_CHARACTER_SPLIT_COUNT
    )
      return `${val.substring(0, val.length / 2)}-\n${val.substring(
        val.length / 2,
        val.length
      )}`
  }
  return val
}

export const checkMultipleExtension = filename => {
  let numberOfExtensions = filename.split(".")
  return !(numberOfExtensions.length > 2)
}

export const getMessageIdentifier = message => {
  if (message && message.type && message.payload)
    switch (message.type) {
      case MESSAGE_TYPES.TEXT:
        return Array.isArray(message.payload.text)
          ? message.payload.text[0]
          : Array.isArray(message.payload)
          ? message.payload[0].text
          : message.payload.text
      case MESSAGE_TYPES.TEXT_WITH_BUTTONS:
      case MESSAGE_TYPES.IMAGE_WITH_BUTTONS:
      case MESSAGE_TYPES.SEAT:
      case MESSAGE_TYPES.UPLOAD_FILE:
      case MESSAGE_TYPES.CAROUSEL:
      case MESSAGE_TYPES.FORM:
      case MESSAGE_TYPES.VIDEO: {
        let identifier = ""
        if (message.payload.title) {
          if (message.payload.subtitle)
            identifier = message.payload.title.concat(message.payload.subtitle)
          else identifier = message.payload.title
        } else if (message.payload.subtitle)
          identifier = message.payload.subtitle
        return identifier
      }
      case MESSAGE_TYPES.UPLOADED_DOCUMENT:
        return message?.payload?.fileName || "Image/File"
      default:
        return ""
    }
  return ""
}

export const compareArrayOfTwoString = (array1, array2) => {
  if (array1.length !== array2.length) return false

  const arr1 = array1.slice()
  arr1.sort()
  const arr2 = array2.slice()
  arr2.sort()

  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false
  }
  return true
}

export const updateProfileSectionData = sections => {
  //TODO: remove this and tell backend developer to do this on server
  sections.forEach(section => {
    if (section.data.length > 0) {
      section.data.forEach(item => {
        if (item && item.type === PROFILE_ITEM_TYPE.CURRENCY)
          item.value = formatPrice(item.value, CURRENCY.RUPEES)
        else if (item && item.type === PROFILE_ITEM_TYPE.DATE) {
          const date = new Date(item.value)
          const option = {
            year: "numeric",
            month: "short",
            day: "numeric",
          }
          item.value = date.toLocaleDateString("en-In", option)
        } else if (item && item.type === PROFILE_ITEM_TYPE.DATE_TIME) {
          const date = new Date(item.value)
          const option = {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          }
          item.value = date.toLocaleTimeString("en-In", option)
        }
      })
    }
  })
  return sections
}

export const disabledDateRange = (
  current,
  days = MODULE_CONFIG.GLOBAL.ALLOWED_DATE_RANGE
) => {
  return current && current < dayjs().subtract(days, "days")
}

export const isAppleDevice = () => {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
}

export const getVersion1MessageIdentifier = message => {
  if (message && message.type)
    switch (message.type) {
      case MESSAGE_TYPES.TEXT:
        return Array.isArray(message.text) ? message.text[0] : message.text
      case MESSAGE_TYPES.TEXT_WITH_BUTTONS:
      case MESSAGE_TYPES.IMAGE_WITH_BUTTONS:
      case MESSAGE_TYPES.VIDEO: {
        let identifier = ""
        if (message.title) {
          if (message.subtitle)
            identifier = message.title.concat(message.subtitle)
          else identifier = message.title
        } else if (message.subtitle) identifier = message.subtitle
        return identifier
      }
      default:
        return ""
    }
  return ""
}

export const linkify = inputText => {
  let linkifiedText = inputText

  // Check if the input text already contains anchor elements
  if (/<a\b[^>]*>.*<\/a>/i.test(inputText)) {
    // If it contains anchor tags, return the input text as is
    return inputText
  }

  // URLs starting with http://, https://, or ftp://
  let urlPattern =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
  linkifiedText = inputText.replace(
    urlPattern,
    '<a href="$1" target="_blank">$1</a>'
  )

  // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  let pseudoUrlPattern = /(^|[^\/])(www\.[\S]+(\b|$))/gim
  linkifiedText = linkifiedText.replace(
    pseudoUrlPattern,
    '$1<a href="http://$2" target="_blank">$2</a>'
  )

  // Change email addresses to mailto:: links.
  let emailPattern = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
  linkifiedText = linkifiedText.replace(
    emailPattern,
    '<a href="mailto:$1">$1</a>'
  )
  return linkifiedText
}

export const isValidEmail = email => {
  if (email) {
    const regex = /^[a-zA-Z0-9._%]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return regex.test(email)
  }
}
export const isValidName = text => {
  return /^[a-zA-Z0-9]+$/.test(text)
}
export const getLanguageFromISOCode = code => {
  const languageObject = new Intl.DisplayNames(["en"], { type: "language" })
  return languageObject.of(code)
}

export const capitalizeFirstLetters = str => {
  if (!str) return ""
  return str.replace(/\b\w/g, char => char.toUpperCase())
}

export const removeQuotesFromArray = (arr = []) => {
  return arr.map(str => str?.trim()?.replace(/["\\]/g, ""))
}
export const removeHtmlTags = (input = "") => {
  return input.replace(/<[^>]*>/g, "")
}

export const convertStringToHTML = htmlString => {
  const parser = new DOMParser()
  const html = parser.parseFromString(htmlString, "text/html")
  return html.body
}

function replaceInnerHtmlOfTsMention(htmlText, fn) {
  const originalHtml = convertStringToHTML(htmlText)

  function replaceElements(element) {
    const children = element.childNodes
    for (let i = children.length - 1; i >= 0; i--) {
      const node = children[i]
      if (
        node.nodeType === 1 &&
        node.tagName.toLowerCase() === "span" &&
        node.id === "ts-mention-blot"
      ) {
        fn(node)
      } else {
        replaceElements(node)
      }
    }
  }

  replaceElements(originalHtml)
  if (originalHtml.innerText === "") {
    return ""
  }
  return originalHtml.innerHTML
}

function checkIfEnvVariableExistById(envVariables, id) {
  const { name, channel } = findNameAndChannelByIdInEnvVars(envVariables, id)
  if (name === "") return { status: false, name }
  return { status: true, name, channel }
}

export function insertNameInTsMention(envVariables, htmlText, classList) {
  return replaceInnerHtmlOfTsMention(htmlText, node => {
    //check if the node exists in env vars by id
    const id = node.getAttribute("data-id")
    const { status: ifEnvExists, name: realName } = checkIfEnvVariableExistById(
      envVariables,
      id
    )
    const [currChannel, tempName] = node.getAttribute("data-name").split("@")
    if (!ifEnvExists) {
      //node does not exist
      //replace node with simple temp text
      node.parentNode.replaceChild(
        document.createTextNode(" @" + tempName + " "),
        node
      )
    } else {
      node.innerHTML = " @" + realName + " "
      //update data-name
      if (tempName !== realName)
        node.setAttribute("data-name", currChannel.concat("@").concat(realName))
    }
  })
}

export function replaceTsMentionById(envVariables, htmlText) {
  return replaceInnerHtmlOfTsMention(htmlText, node => {
    const id = node.getAttribute("data-id")
    const [_, tempName] = node.getAttribute("data-name").split("@")
    const { status: ifEnvExists } = checkIfEnvVariableExistById(
      envVariables,
      id
    )
    if (!ifEnvExists) {
      //if does not exist replace it with just the name
      node.parentNode.replaceChild(document.createTextNode(tempName), node)
    } else {
      //if exists replace it with id
      node.parentNode.replaceChild(
        document.createTextNode("{{" + id + "}}"),
        node
      )
    }
  })
}

export function replaceIdByTsMentionName(envVariables, htmlText) {
  if (htmlText) {
    const replacedText = htmlText.replace(/{{(.*?)}}/g, function (match, id) {
      const {
        status: ifEnvExists,
        name: realName,
        channel,
      } = checkIfEnvVariableExistById(envVariables, id)

      if (ifEnvExists) {
        return channel + "@" + realName
      }
      return id
    })
    return replacedText
  }
  return htmlText
}

export function replaceIdByTsMentionBlot(
  envVariables,
  htmlText,
  regexp = /{{(.*?)}}/g
) {
  if (htmlText) {
    const replacedText = htmlText.replace(regexp, function (match, id) {
      const {
        status: ifEnvExists,
        name: realName,
        channel,
      } = checkIfEnvVariableExistById(envVariables, id)

      if (ifEnvExists) {
        return `<span id="ts-mention-blot" class="ts-mention-${channel}" data-name="${channel}@${realName}" data-id="${id}">@${realName}</span>`
      }
      return id
    })
    return replacedText
  }
  return htmlText
}

export function insertIdInTsMention(envVariables, htmlText) {
  return replaceInnerHtmlOfTsMention(htmlText, node => {
    const id = node.getAttribute("data-id")
    const { status: ifEnvExists, name: realName } = checkIfEnvVariableExistById(
      envVariables,
      id
    )
    const [currChannel, tempName] = node.getAttribute("data-name").split("@")
    if (!ifEnvExists) {
      //node does not exist
      //replace node with simple temp text
      node.parentNode.replaceChild(
        document.createTextNode("@" + tempName),
        node
      )
    } else {
      // Replace the custom element's inner html with the id
      node.innerHTML = `{{${id}}}`
      node.setAttribute("data-name", currChannel.concat("@").concat(realName))
    }
  })
}

export function filterEnvVariablesBySearchString(
  envVariables,
  variableSearchStr,
  key
) {
  let matchedCount = 0
  const fEnv = []
  envVariables.forEach(group => {
    const filteredChildren = group.children.filter((child, ind) => {
      if (
        key &&
        variableSearchStr &&
        child[key].toLowerCase().includes(variableSearchStr.toLowerCase())
      ) {
        matchedCount++
        return true
      } else if (key && !variableSearchStr) {
        matchedCount++
        return true
      }
      return false
    })
    if (filteredChildren.length > 0)
      fEnv.push({ ...group, children: filteredChildren })
  })

  return { matchedCount, fEnv }
}

export const findIdAndChannelByNameInEnvVars = (envVariables, name) => {
  const idResults = []
  envVariables.forEach(group => {
    const children = group.children
    children.forEach(child => {
      if (child.label.toLowerCase() === name.toLowerCase())
        idResults.push({
          id: child.key,
          channel: group.key,
          name: child.label,
        })
    })
  })
  return idResults
}

export const findNameAndChannelByIdInEnvVars = (envVariables, id) => {
  let name = ""
  let channel = ""
  envVariables.forEach(group => {
    const children = group.children
    children.forEach(child => {
      if (child.key === id) {
        name = child.label
        channel = group.key
      }
    })
  })
  return { name, channel }
}

export const ValidateVideoUrl = url => {
  //extension :: /\.(mp4|avi|mov|mpg|mpeg|flv|wmv|webm)$/i
  const videoExtensionPattern = /\.(mp4)$/i
  const youtubePattern =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
  return videoExtensionPattern.test(url) || url.match(youtubePattern)
}

export const getDateRange = (
  start_date_difference = 1,
  end_date_difference = 0
) => {
  const timezone = dayjs.tz.guess()
  const startDateDifference = parseInt(
    MODULE_CONFIG.GLOBAL.DATE_FILTER.START_DATE_DIFFERENCE ||
      start_date_difference
  )
  const endDateDifference = parseInt(
    MODULE_CONFIG.GLOBAL.DATE_FILTER.END_DATE_DIFFERENCE || end_date_difference
  )

  return [
    dayjs().tz(timezone, true).subtract(startDateDifference, "day"),
    dayjs().tz(timezone, true).subtract(endDateDifference, "day"),
  ]
}

export const formatBytes = bytes => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes === 0) return "0 Bytes"
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const size = parseFloat((bytes / Math.pow(1024, i)).toFixed(2))
  return `${size} ${sizes[i]}`
}

export const formatAndBindToJSONToDayjs = (date, configTime = "start") => {
  if (process.env.REACT_APP_ENABLE_TIME_ZONE === "true") {
    const timezone = dayjs.tz.guess()
    date.toJSON = () => {
      if (configTime === "start")
        return (
          date.tz(timezone, true).startOf("day").format("YYYY-MM-DDTHH:mm:ss") +
          "Z"
        )
      else if (configTime === "end")
        return (
          date.tz(timezone, true).endOf("day").format("YYYY-MM-DDTHH:mm:ss") +
          "Z"
        )
      else if (configTime === "current")
        return date.tz(timezone, true).format("YYYY-MM-DDTHH:mm:ss") + "Z"
    }
    return date
  } else return date
}