import { message } from "antd"

import * as API from "./api"
import { fetchDataAndProceed } from "../../utils/dataUtility"
import { METHOD_TYPES } from "../../constants/common"
import { openUrlInNewTab } from "../../utils/common"
import {
  UPDATE_PAGE_STATE,
  GLOBAL_UPDATE_MESSAGE,
  GLOBAL_UPDATE_CHATLIST_ITEM,
  GLOBAL_UPDATE_SELECTED_CHAT,
  GLOBAL_UPDATE_ACTIVE_CHATS_ITEM,
} from "./actiontypes"
import { updateChatsState } from "../chats_details/actions"

export const updatePageState = payload => ({
  type: UPDATE_PAGE_STATE,
  payload,
})

// ----------------------------- API CALLS --------------------------------

export const translateMessage = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.translateMessage,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (err, res) => {
      if (
        !err &&
        res &&
        res.data &&
        res.data.message &&
        res.data.chatlogId &&
        res.data.psid
      ) {
        dispatch({
          type: GLOBAL_UPDATE_MESSAGE,
          payload: res.data,
        })
        if (callback) callback()
        message.success(
          res && res.message ? res.message : `${data.vote} success.`
        )
      } else {
        if (callback) callback()
      }
    }
  )
}

export const voteChatlog = data => dispatch => {
  fetchDataAndProceed(
    {
      url: API.voteChatlog,
      method: METHOD_TYPES.PUT,
      data,
    },
    (error, response) => {
      if (!error && response && response.data) {
        dispatch({
          type: GLOBAL_UPDATE_MESSAGE,
          payload: response.data,
        })
        message.success(
          response && response.message
            ? response.message
            : `${data.vote} success.`
        )
      }
    }
  )
}

export const trainYourBot = () => dispatch => {
  dispatch(updatePageState({ bot_training: true }))
  fetchDataAndProceed(
    {
      url: API.trainYourBot,
      method: METHOD_TYPES.POST,
      loader: false,
    },
    (error, response) => {
      dispatch(updatePageState({ bot_training: false }))
      if (!error)
        message.loading(
          response && response.message
            ? response.message
            : "bot training in progress."
        )
    }
  )
}

export const publishYourBot = () => dispatch => {
  dispatch(updatePageState({ bot_publishing: true }))
  fetchDataAndProceed(
    {
      url: API.publishYourBot,
      method: METHOD_TYPES.POST,
      loader: false,
    },
    (error, response) => {
      dispatch(updatePageState({ bot_publishing: false }))
      if (!error)
        message.loading(
          response && response.message
            ? response.message
            : "bot publishing in progress."
        )
    }
  )
}

export const getPreemptiveData = () => dispatch => {
  fetchDataAndProceed(
    {
      url: API.getPreemptiveData,
      loader: false,
    },
    (error, response) => {
      if (
        !error &&
        response &&
        response.actions &&
        response.agentStates &&
        response.eventTags &&
        response.eventTypes &&
        response.messages &&
        response.variables &&
        response.variables
      ) {
        dispatch(
          updatePageState({
            preemptive_data: {
              actions: response.actions,
              variables: response.variables,
              messages: response.messages,
              event_tags: response.eventTags,
              event_types: response.eventTypes,
              agent_states: response.agentStates,
              workflows: response.workflows,
            },
          })
        )
      } else
        message.error(
          response && response.message
            ? response.message
            : "Unable to fetch preemptive data"
        )
    }
  )
}

export const whatsAppFileUpload = data => dispatch => {
  dispatch(updatePageState({ wa_file_uploading: true }))
  fetchDataAndProceed(
    {
      url: API.whatsAppFileUpload,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
      content_type: "multipart/form-data",
      timeout: 0,
    },
    (error, response) => {
      dispatch(updatePageState({ wa_file_uploading: false }))
      if (!error)
        message.success(
          response && response.message
            ? response.message
            : "File uploaded successfully."
        )
    }
  )
}

export const getChatlogFilters = () => dispatch => {
  fetchDataAndProceed(
    {
      url: API.getChatlogFilters,
      loader: false,
    },
    (err, res) => {
      if (!err && res) {
        dispatch(updatePageState({ chatlogs_filters: res }))
      }
    }
  )
}

export const getCallLogs = (data, callback, errorCallback) => () => {
  fetchDataAndProceed(
    {
      url: API.getCallLog,
      loader: false,
      data
    },
    (err, res) => {
      if (!err && res?.data?.callLogList) {
        if(callback) callback(res.data.callLogList)
        
      }else {
        if(errorCallback) errorCallback()
      }
    }
  )
}

export const chatAnalyticsBySessionId = data => (dispatch) => {
  fetchDataAndProceed(
    {
      url: API.chatAnalyticsBySessionId,
      loader: false,
      data
    },
    (err, res) => {
      if (!err && res.data?.analytics) {
        dispatch(updatePageState({ chatAnalyticsBySessionId: res.data?.analytics })) 
      }
    }
  )
}

export const updateUserBlockStatus = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.updateUserBlockStatus,
      method: METHOD_TYPES.PUT,
      data,
    },
    (err, res) => {
      if (
        !err &&
        res &&
        res.data &&
        res.data.sessionId &&
        res.data.blockedStatus
      ) {
        dispatch({
          type: GLOBAL_UPDATE_CHATLIST_ITEM,
          payload: {
            sessionId: res.data.sessionId,
            changedValue: {
              blockedStatus: res.data.blockedStatus,
            },
          },
        })
      }
      if (callback) callback()
    }
  )
}

export const updateCustomerProfileFormList = (data, callback) => dispatch => {
  dispatch(
    updateChatsState({
      buttonLoader: {
        loader: true,
        index: data?.updatedFormData?.buttonIndex,
        id: data?.updatedFormData?.id,
      },
    })
  )
  fetchDataAndProceed(
    {
      url: API.updateCustomerProfileFormList,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
      modalLoader: true,
    },
    (err, res) => {
      if (
        !err &&
        res &&
        res.data &&
        res.data.sessionId &&
        res.data.userProfileWidget
      ) {
        if (data.id)
          dispatch({
            type: GLOBAL_UPDATE_ACTIVE_CHATS_ITEM,
            payload: {
              id: data.id,
              key: "profile",
              value: {
                userProfileWidget: res.data.userProfileWidget,
              },
              is_array: false,
            },
          })
        else
          dispatch({
            type: GLOBAL_UPDATE_SELECTED_CHAT,
            payload: {
              sessionId: res.data.sessionId,
              key: "profile",
              value: {
                userProfileWidget: res.data.userProfileWidget,
              },
            },
          })
        message.success(res.message ? res.message : "Successfully updated!")
        dispatch(
          updateChatsState({
            buttonLoader: {
              loader: false,
              index: data?.updatedFormData?.buttonIndex,
              id: data?.updatedFormData?.id,
            },
          })
        )
        if (callback) callback()
      } else {
        dispatch(
          updateChatsState({
            buttonLoader: {
              loader: false,
              index: data?.updatedFormData?.buttonIndex,
              id: data?.updatedFormData?.id,
            },
          })
        )
      }
    }
  )
}

export const updateTagsInConversations = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.updateTagsInConversations,
      method: METHOD_TYPES.PUT,
      data,
    },
    (err, res) => {
      console.log("updateTagsInConversations", err, res)
      if (
        !err &&
        res &&
        res.data &&
        res.data.sessionId &&
        res.data.selectedTags
      ) {
        if (data.id)
          dispatch({
            type: GLOBAL_UPDATE_ACTIVE_CHATS_ITEM,
            payload: {
              id: data.id,
              key: "profile",
              value: {
                selectedTags: res.data.selectedTags,
              },
              is_array: false,
            },
          })
        else
          dispatch({
            type: GLOBAL_UPDATE_SELECTED_CHAT,
            payload: {
              sessionId: res.data.sessionId,
              key: "profile",
              value: {
                selectedTags: res.data.selectedTags,
              },
            },
          })
        message.success(res.message ? res.message : "Successfully updated!")
        if (callback) callback()
      }
    }
  )
}

export const getAllowedMessageVariations = (successCB, errorCB) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.allowedMessageVariations,
    },
    (err, res) => {
      if (!err && res && res.data?.variationList) {
        dispatch(
          updatePageState({
            allowedMessageVariations: res.data.variationList,
          })
        )
        if (successCB) successCB()
      } else if (errorCB) errorCB()
    }
  )
}

export const deleteUploadedMedia = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.mediaDelete,
      method: METHOD_TYPES.DELETE,
      data,
      loader: false,
    },
    (err, res) => {
      if (callback) callback()
      if (!err && res) {
        message.success(`${data.mediaType || "Media"} deleted successfully`)
      }
    }
  )
}

export const downloadChatlogs = data => dispatch => {
  fetchDataAndProceed(
    {
      url: API.downloadChatlogs,
      method: METHOD_TYPES.POST,
      data,
      loader: false,
    },
    (error, res) => {
      if (!error && res?.data?.downloadUrl) {
        openUrlInNewTab(res?.data?.downloadUrl)
      }
    }
  )
}

export const bulkFileUpload = (data, callback) => dispatch => {
  fetchDataAndProceed(
    {
      url: API.bulkFileUpload,
      method: METHOD_TYPES.POST,
      data,
      modalLoader: true,
      content_type: "multipart/form-data",
    },
    (error, response) => {
      if (!error) {
        if (callback) callback()
        message.success(
          response.message ? response.message : "File uploaded successfully."
        )
      }
    }
  )
}

export const maintenanceAlert = callBack => () => {
  fetchDataAndProceed(
    {
      url: API.maintainanceAlert,
      loader: false,
      axios_args: {
        headers: {
          "Content-Type": "application/json",
          Authorization:"",
        },
      },
    },
    (error, res, errRes) => {
      if (!error && res?.data?.maintenance) {
        if (callBack) callBack(res.data?.maintenance)
      } else if ((error, null, errRes)) {
        if (callBack) callBack({}, errRes.status)
      }
    }
  )
}
